<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>История звонков</v-card-title>
          <DatePeriod v-model="filters.period" @change="reloadTable(false)"/>
        <v-card-actions>
          <v-row>
            <v-chip @click="callLine = line"
                    v-for="(lineTitle, line) in callLines" :key="line" :color="callLine===line ? '#9BF0F5' : '#fff'"
                    class="mr-2" label>
              {{ lineTitle }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <CBadge v-bind="attrs" v-on="on" style="margin-left: 5px;color: white;background: #8a93a2">
                    {{ rows.filter(row => row.callLine === line).length }}
                  </CBadge>
                </template>
                <span>Всего</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <CBadge v-bind="attrs" v-on="on" style="margin-left: 5px;" color="success">
                    {{ rows.filter(row => (row.callLine === line && row.isIncoming)).length }}
                  </CBadge>
                </template>
                <span>Принято</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <CBadge v-bind="attrs" v-on="on" style="margin-left: 5px;" color="danger">
                    {{ rows.filter(row => (row.callLine === line && row.isMissed)).length }}
                  </CBadge>
                </template>
                <span>Пропущено</span>
              </v-tooltip>

            </v-chip>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="rows.filter(row=>row.callLine===callLine)"
              :headers="headers"
              item-key="id"
              :loading="loadingTable"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Список пуст"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <tr>
                <td>{{ index + 1 }} ({{item.id}})
                  <v-icon v-if="item.record" @click="$eventBus.$emit('playAudio', item.record)" color="blue">
                    mdi-play-circle-outline
                  </v-icon>
                </td>
                <td> {{ item.datetime_createObj.time }} {{ $root.dateToRus(item.datetime_createObj.date, 1) }}</td>
                <td>
                  <template v-if="Number(item.datetime_start)">
                    {{ item.datetime_startObj.time }}, через {{ item.datetime_start - item.datetime_create }} с
                  </template>
                </td>
                <td>
                  {{ item.datetime_endObj.time }}
                </td>
                <td>
                  <template v-if="parseInt(item.datetime_end)">
                    {{ $root.printTimeDiff(item.datetime_end - (item.datetime_start * 1 || item.datetime_create)) }}
                  </template>
                </td>
                <td>
                  <v-chip small :color="item.color" style="color: white">
                    {{ item.statusStr }}
                  </v-chip>
                </td>
                <td>
                  <template v-if="!item.client || item.callDirection!=='incoming'">{{ item.phone_from }}</template>
                  <template v-if="item.callDirection==='incoming'">
                    <ClientLink v-if="item.client" :value="item.client"></ClientLink>
                    <CleanerLink v-if="item.cleaner" :value="item.cleaner"></CleanerLink>
                  </template>
                </td>
                <td style="min-width: 170px">
                  <template v-if="!item.client || item.callDirection!=='outgoing'">{{ item.phone_to }}</template>
                  <template v-if="item.callDirection==='outgoing'">
                    <ClientLink v-if="item.client" :value="item.client"></ClientLink>
                    <CleanerLink v-if="item.cleaner" :value="item.cleaner"></CleanerLink>
                  </template>
                </td>
                <td style="max-width: 300px">
                  <template v-if="item.utm">
                    <div v-for="(val, key) in item.utm.utm">{{ key }}: <span style="color: #2196f3">{{ val }}</span>
                    </div>
                    <div v-if="item.utm.$data && item.utm.$data.first_page">Страница входа:
                      {{ item.utm.$data.first_page.url }}
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>

</template>

<script>
import ClientLink from "../../components/ClientLink";
import CleanerLink from "../../components/CleanerLink";
import DatePeriod from "../../components/DatePeriod.vue";

export default {
  name: "Table",
  components: {
    DatePeriod,
    CleanerLink,
    ClientLink,
  },
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      filters: {
        period: [today, today],
        from: today,
        to: today,
      },
      rows: [],
      callLines: [],
      loadingTable: false,
      callLine: 'clients',
      headers: [
        {text: '№'},
        {text: 'Поступил звонок'},
        {text: 'Начало разговора'},
        {text: 'Окончание звонка'},
        {text: 'Продол.'},
        {text: 'Статус'},
        {text: 'Кто'},
        {text: 'Кому'},
        {text: 'Метки'},
      ]
    }
  },
  methods: {
    reloadTable(mainThread = false) {
      this.filters.from = this.filters.period[0];
      this.filters.to = this.filters.period[1];
      this.$store.state.server.request('calls/get', this.filters, (data) => {
        this.rows = data.response;
        this.callLines = data.callLines;
        if (mainThread) setTimeout(() => {
          if (this.$router.currentRoute.path === '/calls') this.reloadTable(mainThread)
        }, 5000)
      }, () => {
        if (mainThread) setTimeout(() => {
          if (this.$router.currentRoute.path === '/calls') this.reloadTable(mainThread)
        }, 5000);
      })
    },
  },
  mounted() {
    this.reloadTable(true)
  },
}
</script>

<style scoped>

</style>
